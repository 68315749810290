/*
 * @Description: 原生调用
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-06-04 14:05:09
 * @LastEditors: John Holl
 * @LastEditTime: 2021-06-04 14:14:46
 */

import store from '@/store'

const hybrid = {
  install (Vue, options) {
    // 把当前的对象挂载到vue的全局
    Vue.prototype.$hybrid = this
  },
  // 供原生端调用，改变经纬度
  changeCoordinate (coordinate) {
    console.log(coordinate)
    store.dispatch('app/setCoordinate', coordinate)
  }
}

// 把vue中绑定的对象挂载到window上
window.Hybrid = hybrid

export default hybrid
