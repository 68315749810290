/*
 * @Description:用户端-首页-停车缴费    payForParking
 * @Author: LinFeng
 * @Github: https://github.com/LinFeng-tt
 * @Date: 2021-04-23 17:26:05
 * @LastEditors: zhoucheng
 */

import request from '@/utils/request'

const prefix = '/order'
const prefix1 = 'bill'

// 获取在线订单
export function queryOnlineOrderByPlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryOnlineOrderByPlateNumber',
    method: 'post',
    data: param
  })
}

// 获取订单详情
export function queryOnlineOrderBySinglePlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryOnlineOrderBySinglePlateNumber',
    method: 'post',
    data: param
  })
}
// 取历史订单
export function queryCompletedOrderByPlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryCompletedOrderByPlateNumber',
    method: 'post',
    data: param
  })
}

// 微信支付
export function wxAppPay (param) {
  return request({
    url: prefix + '/wxPublicPay/jsApiPay',
    method: 'post',
    data: param
  })
}

// 根据计费规则id获取计费规则
export function queryBillingRuleById (param) {
  return request({
    url: prefix1 + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}

// 订单免费
export function completeOrderFreeV2 (param) {
  return request({
    url: prefix + '/OrderController/completeOrderFreeV2' + '/' + param.orderSequence,
    method: 'get',
    data: param
  })
}

// 订单已支付
export function completeOrderFree (param) {
  return request({
    url: prefix + '/OrderController/completeOrderFree' + '/' + param.orderSequence + '/' + param.billingRuleDefId,
    method: 'get',
    data: param
  })
}
