/*
 * @Description:用户端-底部我的-反馈建议 problemFeedBack
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:51:55
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 获取反馈建议
export function queryFeedBackList (param) {
  return request({
    url: prefix + '/feedback/queryFeedBackListWithAuth',
    method: 'post',
    data: param
  })
}

// 新增馈建议
export function addFeedBack (param) {
  return request({
    url: prefix + '/feedback/addFeedBack',
    method: 'post',
    data: param
    // headers: {
    //   'Content-Type': 'application/form-data'
    // }
  })
}

// 上传图片
export function upFile (param) {
  return request({
    url: prefix + '/file/upFile',
    method: 'post',
    data: param
  })
}
