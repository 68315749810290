/*
 * @Description:无牌车查询订单 noPlateNumberPay
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-17 13:58:20
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'
const prefix1 = '/login'

// 无牌车订单免费
export function completeOrderFreeV2NotUserToken (param) {
  return request({
    url: prefix + '/OrderController/completeOrderFreeV2NotUserToken' + '/' + param.orderSequence,
    method: 'get',
    data: param
  })
}
// 获取openid
export function getOpenIdByCode (param) {
  return request({
    url: prefix1 + '/ConsumerLoginController/getOpenIdByCode',
    method: 'post',
    params: param
    // headers: { 'content-type': 'multipart/form-data' }
  })
}
// 支付
export function jsApiPayNoUserToken (param) {
  return request({
    url: prefix + '/wxPublicPay/jsApiPayNoUserToken',
    method: 'post',
    data: param
  })
}

// 获取订单
export function getOrderByOpenid (param) {
  return request({
    url: prefix + '/OrderController/getOrderByOpenid',
    method: 'post',
    data: param
  })
}
// 创建的订单
export function createOrder4NoPlate (param) {
  return request({
    url: prefix + '/OrderController/createOrder4NoPlate',
    method: 'post',
    data: param
  })
}
// 完结订单
export function finishOrder4NoPlate (param) {
  return request({
    url: prefix + '/OrderController/finishOrder4NoPlate',
    method: 'post',
    data: param
  })
}
