/*
 * @Description:用户信息 实名认证 authenticateSelf
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 13:51:24
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 用户实名认证
export function idIdentify (param) {
  return request({
    url: prefix + '/user/idIdentify',
    method: 'post',
    data: param
  })
}

// 查询实名认证记录 无参
export function getIdIdentifyRecord (param) {
  return request({
    url: prefix + '/user/getIdIdentifyRecord',
    method: 'get',
    data: param
  })
}
