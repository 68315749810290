/*
 * @Description: 主入口文件
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-06-04 13:57:58
 * @LastEditors: zhoucheng
 * @LastEditTime: 2023-03-23 19:36:57
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.less'
import './styles/index.less'
import fmtDate from './utils/fmtDate.js'
import api from './api/index'
import storage from './utils/storage.js'
import deepClone from './utils/deepClone.js'

// 自定义插件
import hybrid from './utils/nativePls'
import publicVar from './utils/publicVar'

// 高德地图 npm i @amap/amap-jsapi-loader --save-dev  npm install -s vue-amap
import AMap from 'vue-amap'

// vconsole
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

AMap.initAMapApiLoader({
  key: 'f3b01243929a23cf9b3ba2deeb49fc4b',
  plugin: [
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.Geolocation'
  ],
  v: '1.4.15'
})

Vue.use(hybrid)
Vue.use(publicVar)
Vue.use(AMap)

Vue.config.productionTip = false
// 封装vant
Vue.use(Vant)
// 封装axios
Vue.use(api)
// 使用storage
Vue.prototype.$cookie = storage

Vue.prototype.$fmtDate = fmtDate

Vue.prototype.$deepClone = deepClone
const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
