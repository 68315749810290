/*
 * @Description:停车记录
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-22 10:50:20
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'
// 查询绑定车牌
export function queryBindPlateNumber (param) {
  return request({
    url: prefix + '/user/queryBindPlateNumber',
    method: 'get',
    data: param
  })
}
