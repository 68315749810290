/*
 * @Description:
 * @Author: yanxiao
 * @Github: https://github.com/yanxiaos
 * @Date: 2021-06-30 15:00:23
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 获取开票记录
export function queryInvoiceRecord (param) {
  return request({
    url: prefix + '/invoice/queryInvoiceRecord',
    method: 'post',
    data: param
  })
}
// 发票反查支付记录
export function getPaymentRecordByInvoice (param) {
  return request({
    url: prefix + '/payment/getPaymentRecordByInvoice',
    method: 'post',
    data: param
  })
}
export function getOrderByPayment (param) {
  return request({
    url: prefix + '/payment/getOrderByPayment',
    method: 'post',
    data: param
  })
}

// 获取可开票订单
export function getPaymentRecordToInvoice (param) {
  return request({
    url: prefix + '/payment/getPaymentRecordToInvoice',
    method: 'get',
    data: param
  })
}

// 申请开票 个人 单位
export function invoice (param) {
  return request({
    url: prefix + '/invoice/invoice',
    method: 'post',
    data: param
  })
}

// 重新获取 开票结果 刷新开票状态
export function getInvoiceAgain (param) {
  return request({
    url: prefix + '/invoice/getInvoiceAgain',
    method: 'post',
    data: param
  })
}

// 申请重新开票
export function applyReInvoice (param) {
  return request({
    url: prefix + '/invoice/applyReInvoice',
    method: 'post',
    data: param
  })
}
