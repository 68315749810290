/*
 * @Description: 请求接口入口
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:39:39
 * @LastEditors: zhoucheng
 */

import * as commonPage from './commonPage/commonPage.js'
import * as payOnBehalfDetails from './commonPage/payOnBehalfDetails.js'
// 下拉接口
import * as queryDict from './queryDict/queryDict.js'

// 停车缴费
import * as payForParking from './wxPayForParking/payForParking.js'
// 停车缴费 订单详情
import * as payForParkCount from './wxPayForParking/payForParkCount.js'
// 停车缴费 欠费订单
import * as lackOrder from './wxPayForParking/lackOrder.js'
// 订单申诉
import * as orderComplain from './wxPayForParking/orderComplain.js'
// 反向寻车
import * as reverseSearch from './wxPayForParking/reverseSearch.js'
// 车位查询订单
import * as payForSpacePark from './wxPayForParking/payForSpacePark.js'

// 周边泊位
import * as aroundParkSpace from './wxAroundParkSpace/aroundParkSpace.js'

// 停车记录
import * as parkRecord from './wxParkRecord/parkRecord.js'

// 支付信息
import * as payRecord from './wxPayRecord/payRecord.js'

// 发票
import * as invoiceApi from './wxInvoice/invoiceApi.js'

// 我的 个人信息
import * as selfInfo from './wxSelfInfo/selfInfo.js'
// 用户信息 实名认证
import * as authenticateSelf from './wxSelfInfo/authenticateSelf.js'

// 车牌列表 车牌绑定
import * as bindPlateNumber from './wxBindPlateNumber/bindPlateNumber.js'

// 反馈建议
import * as adviseFeedBack from './wxAdviseFeedBack/problemFeedBack.js'

// 支付扫码
import * as payScanCode from './payScanCode/payScanCode.js'

// 巡检员支付订单
import * as partrolPersonPage from './partrolPersonPage/partrolPersonPage.js'

// 无牌车
import * as noPlateNumberPay from './noPlateNumberPay/noPlateNumberPay.js'

// 二维码扫码支付
import * as alipayCode from './alipayCode/alipayCode.js'

const install = function (Vue) {
  if (install.installed) {
    return (install.installed = true)
  }
  Object.defineProperties(Vue.prototype, {
    $commonPage: {
      get () {
        return commonPage
      }
    },
    $payOnBehalfDetails: {
      get () {
        return payOnBehalfDetails
      }
    },
    $queryDict: {
      get () {
        return queryDict
      }
    },
    $payForParking: {
      get () {
        return payForParking
      }
    },
    $payForParkCount: {
      get () {
        return payForParkCount
      }
    },
    $orderComplain: {
      get () {
        return orderComplain
      }
    },
    $reverseSearch: {
      get () {
        return reverseSearch
      }
    },
    $aroundParkSpace: {
      get () {
        return aroundParkSpace
      }
    },
    $parkRecord: {
      get () {
        return parkRecord
      }
    },
    $payRecord: {
      get () {
        return payRecord
      }
    },
    $invoiceApi: {
      get () {
        return invoiceApi
      }
    },
    $selfInfo: {
      get () {
        return selfInfo
      }
    },
    $bindPlateNumber: {
      get () {
        return bindPlateNumber
      }
    },
    $adviseFeedBack: {
      get () {
        return adviseFeedBack
      }
    },
    $lackOrder: {
      get () {
        return lackOrder
      }
    },
    $authenticateSelf: {
      get () {
        return authenticateSelf
      }
    },
    $payScanCode: {
      get () {
        return payScanCode
      }
    },
    $partrolPersonPage: {
      get () {
        return partrolPersonPage
      }
    },
    $payForSpacePark: {
      get () {
        return payForSpacePark
      }
    },
    $noPlateNumberPay: {
      get () {
        return noPlateNumberPay
      }
    },
    $alipayCode: {
      get () {
        return alipayCode
      }
    }
  })
}

export default {
  install
}
