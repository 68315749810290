/*
 * @Description:我的 个人信息 selfInfo
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 14:56:20
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/login'

// 查询用户信息
export function getUserInfo (param) {
  return request({
    url: prefix + '/ConsumerLoginController/getUserInfo',
    method: 'post',
    data: param
  })
}

// 修改信息
export function changeUserInfo (param) {
  return request({
    url: prefix + '/ConsumerLoginController/changeUserInfo',
    method: 'post',
    data: param
  })
}

// 上传头像路径
export function uploadAvatar (param) {
  return request({
    url: prefix + '/ConsumerLoginController/uploadAvatar',
    method: 'post',
    data: param
  })
}

// //  根据code获取token
// //  该方法写在@/utils/getUserInfo.js, 不走全局axios
// export function appLoginWithWxCode (param) {
//   return request({
//     url: prefix + '/ConsumerLoginController/appLoginWithWxCode',
//     method: 'post',
//     data: param
//   })
// }

// 发送手机验证码
export function sendSms (param) {
  return request({
    url: prefix + '/ConsumerLoginController/sendSms',
    method: 'post',
    data: param
  })
}

// 绑定微信openId和手机号
export function bindWxUser (param) {
  return request({
    url: prefix + '/ConsumerLoginController/bindWxUser',
    method: 'post',
    data: param
  })
}

/**
 * 根据 userCode，支付类型 获取 支付id （微信openId、支付宝id
 * type: 0支付宝、1微信、2公众号
 */
export function getPayId (param) {
  return request({
    url: prefix + '/userPay/getPayId',
    method: 'post',
    data: param
  })
}

// 查看手机号是否已经绑定
export function changeBindPhoneNumber (param) {
  return request({
    url: prefix + '/ConsumerLoginController/changeBindPhoneNumber',
    method: 'post',
    data: param
  })
}

// 换绑手机号
export function changePhoneNumber (param) {
  return request({
    url: prefix + '/ConsumerLoginController/changePhoneNumber',
    method: 'post',
    data: param
  })
}
