/*
 * @Description:支付宝扫码 alipayCode
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-02-24 11:47:59
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const preFix = '/order'

// 获取支付链接
export function aliPay (param) {
  return request({
    url: preFix + '/aliPay/f2FPay',
    method: 'post',
    data: param
  })
}
