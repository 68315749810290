/*
 * @Description:缴费记录 payRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-11 09:27:42
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/mobile'

// 获取订单详情
export function getOrderByPayment (param) {
  return request({
    url: prefix + '/payment/getOrderByPayment',
    method: 'post',
    data: param
  })
}

// 获取支付信息
export function getPaymentRecord (param) {
  return request({
    url: prefix + '/payment/getPaymentRecord',
    method: 'get',
    data: param
  })
}

// 取历史订单
export function queryCompletedOrderByPlateNumber (param) {
  return request({
    url: '/order' + '/OrderController/queryCompletedOrderByPlateNumber',
    method: 'post',
    data: param
  })
}

// 根据计费ID查计费规则详情
export function queryBillingRulesById (param) {
  return request({
    url: '/bill' + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}
