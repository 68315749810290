/*
 * @Description: getter汇总文件
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 21:48:11
 * @LastEditors: John Holl
 */

const getters = {
  sidebar: state => state.app.sidebar
}

export default getters
