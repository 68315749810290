/*
 * @Description:欠费订单 lackOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-19 15:57:33
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

// 欠费订单
export function queryArrangeOrder (param) {
  return request({
    url: prefix + '/OrderController/queryArrangeOrder',
    method: 'post',
    data: param
  })
}

// 根据车牌号车牌颜色查询欠费金额欠费订单
export function queryArrangeOrderByPlateCode (param) {
  return request({
    url: prefix + '/OrderController/getArrearsOrderInfoByPlateNumberAndCode',
    method: 'post',
    data: param
  })
}
