/*
 * @Description:停车 停车场查询 carParking
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-27 14:30:46
 * @LastEditors: yanxiao
 */
import request from '@/utils/request'

const prefix = '/mobile'
const prefix1 = '/operate'
const prefix2 = '/bill'

// 获取附近停车场
export function parkNearByNoLimit (param) {
  return request({
    url: prefix + '/berth/parkNearByNoLimit',
    method: 'post',
    data: param
  })
}

// 根据停车场id获取停车场信息
export function queryParking (param) {
  return request({
    url: prefix1 + '/ParkController/queryParkAll',
    method: 'post',
    data: param
  })
}

// 根据计费规则id获取计费规则详情
export function queryBillingRule (param) {
  return request({
    url: prefix2 + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}
