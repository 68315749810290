/*
 * @Description:首页-代缴车费（车牌绑定）payForBehalf
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-22 10:50:20
 * @LastEditors: haojing
 */
import request from '@/utils/request'

const prefix = '/mobile'
// 查询绑定车牌
export function queryBindPlateNumber (param) {
  return request({
    url: prefix + '/user/queryBindPlateNumber',
    method: 'get',
    data: param
  })
}

// 新增绑定车牌
export function bindPlateNumber (param) {
  return request({
    url: prefix + '/user/bindPlateNumber',
    method: 'post',
    data: param
  })
}
// 车牌解绑
export function unBindPlateNumber (param) {
  return request({
    url: prefix + '/user/unBindPlateNumber',
    method: 'post',
    data: param
  })
}
