/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-15 11:13:14
 * @LastEditors: zhoucheng
 */

const publicVar = {
  install (Vue) {
    // 把当前的对象挂载到vue的全局
    Vue.prototype.$imgBaseUrl = this.imgBaseUrl
    Vue.prototype.$pdfBaseUrl = this.pdfBaseUrl
    Vue.prototype.$headPhotoUrl = this.headPhotoUrl
  },
  // 图片baseurl
  imgBaseUrl: '/cqparkApi/operate/upload/imgs/',

  pdfBaseUrl: '/cqparkApi/operate/invoice/',

  // https://gateway.cqybxm.com
  headPhotoUrl: '/cqparkApi'

}

export default publicVar
