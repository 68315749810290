import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'codeQuery',
    redirect: '/codeQuery',
    meta: {
      name: '重定向页面'
    }
  },
  // 获取code codeQuery
  {
    path: '/codeQuery',
    name: 'codeQuery',
    component: () => import('@/views/codeQuery/index.vue'),
    meta: { name: '空白页code', type: 'list' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/payOnBehalfDetails',
    name: 'payOnBehalfDetails',
    component: () => import('@/views/commonPage/payOnBehalfDetails/index.vue'),
    meta: { name: '待缴费总览', type: 'list' }
  },
  {
    path: '/onlineOrderDetailNoPayBut',
    name: 'onlineOrderDetailNoPayBut',
    component: () => import('@/views/commonPage/onlineOrderDetailNoPayBut/index.vue'),
    meta: { name: '在线订单页面无交费按钮', type: 'list' }
  },
  {
    path: '/lackOrderDetailNoPayBut',
    name: 'lackOrderDetailNoPayBut',
    component: () => import('@/views/commonPage/lackOrderDetailNoPayBut/index.vue'),
    meta: { name: '欠费订单页面无交费按钮', type: 'list' }
  },
  {
    path: '/billRuleDetail',
    name: 'billRuleDetail',
    component: () => import('@/views/commonPage/billRuleDetail/index.vue'),
    meta: { name: '计费规则详情页', type: 'list' }
  },
  {
    path: '/orderComplain',
    name: 'orderComplain',
    component: () => import('@/views/commonPage/orderComplain/index.vue'),
    meta: { name: '订单申诉', type: 'list' }
  },

  // 停车缴费
  {
    path: '/payForParking',
    name: 'payForParking',
    component: () => import('@/views/payForParking/index.vue'),
    meta: { name: '停车缴费', type: 'list' }
  },
  // 停车缴费-停车代缴-停车支付
  {
    path: '/parForParkCount',
    name: 'parForParkCount',
    component: () => import('@/views/payForParking/parForParkCount/index.vue'),
    meta: {
      name: '停车缴费-停车代缴-停车支付',
      type: 'list'
    }
  },
  // 停车缴费-欠费订单
  {
    path: '/lackOrder',
    name: 'lackOrder',
    component: () => import('@/views/payForParking/lackOrder/index.vue'),
    meta: {
      name: '停车缴费-欠费订单',
      type: 'list'
    }
  },
  // 停车缴费 欠费订单详情
  {
    path: '/lackOrderDetail',
    name: 'lackOrderDetail',
    component: () => import('@/views/payForParking/lackOrder/lackOrderDetail/index.vue'),
    meta: {
      name: '停车缴费-欠费订单详情',
      type: 'list'
    }
  },

  //  支付方式选择
  {
    path: '/choosePayStyle',
    name: 'choosePayStyle',
    component: () => import('@/views/payForParking/choosePayStyle/index.vue'),
    meta: {
      name: '支付方式选择',
      type: 'list'
    }
  },
  // 停车缴费-停车代缴
  {
    path: '/payForBehalf',
    name: 'payForBehalf',
    component: () => import('@/views/payForParking/payForBehalf/index.vue'),
    meta: {
      name: '停车缴费-停车代缴',
      type: 'list'
    }
  },

  // 停车缴费-车位查询代缴
  {
    path: '/payForSpacePark',
    name: 'payForSpacePark',
    component: () => import('@/views/payForParking/payForSpacePark/index.vue'),
    meta: {
      name: '停车缴费-车位查询代缴',
      type: 'list'
    }
  },
  {
    path: '/payForSpaceParkOnline',
    name: 'payForSpaceParkOnline',
    component: () => import('@/views/payForParking/payForSpacePark/payForSpaceParkOnline/index.vue'),
    meta: {
      name: '停车缴费-车位查询代缴-在线订单',
      type: 'list'
    }
  },
  //  反向寻车
  {
    path: '/reverseSearch',
    name: 'reverseSearch',
    component: () => import('@/views/payForParking/reverseSearch/index.vue'),
    meta: {
      name: '底部停车-停车地图详情',
      type: 'list'
    }
  },

  //  周边泊位
  {
    path: '/aroundParkSpace',
    name: 'aroundParkSpace',
    component: () => import('@/views/aroundParkSpace/index.vue'),
    meta: {
      name: '周边泊位',
      type: 'list'
    }
  },
  //  周边泊位-详情
  {
    path: '/aroundParkInfo',
    name: 'aroundParkInfo',
    component: () => import('@/views/aroundParkSpace/aroundParkInfo/index.vue'),
    meta: {
      name: '周边泊位-详情',
      type: 'list'
    }
  },

  //  停车记录
  {
    path: '/parkRecord',
    name: 'parkRecord',
    component: () => import('@/views/parkRecord/index.vue'),
    meta: {
      name: '停车记录',
      type: 'list'
    }
  },

  // 支付信息
  {
    path: '/payRecord',
    name: 'payRecord',
    component: () => import('@/views/payRecord/index.vue'),
    meta: {
      name: '支付信息',
      type: 'list'
    }
  },
  // 支付信息-支付详情
  {
    path: '/payRecordInfo',
    name: 'payRecordInfo',
    component: () => import('@/views/payRecord/payRecordInfo/index.vue'),
    meta: {
      name: '支付信息-详情',
      type: 'list'
    }
  },

  // 发票中心
  {
    path: '/invoiceCenter',
    name: 'invoiceCenter',
    component: () => import('@/views/invoiceCenter/index.vue'),
    meta: {
      name: '发票中心',
      type: 'list'
    }
  },
  // 发票中心-停车费用开票
  {
    path: '/paymentHistory',
    name: 'paymentHistory',
    component: () => import('@/views/invoiceCenter/paymentHistory/index.vue'),
    meta: {
      name: '发票中心-可开票订单',
      type: 'list'
    }
  },
  // 发票中心-停车费用开票-填写发票信息
  {
    path: '/fillInvoice',
    name: 'fillInvoice',
    component: () => import('@/views/invoiceCenter/paymentHistory/fillInvoice/index.vue'),
    meta: {
      name: '发票中心-停车费用开票-填写发票信息',
      type: 'list'
    }
  },
  // 发票中心-开票记录
  {
    path: '/invoiceHistory',
    name: 'invoiceHistory',
    component: () => import('@/views/invoiceCenter/invoiceHistory/index.vue'),
    meta: {
      name: '发票中心-开票记录',
      type: 'list'
    }
  },
  // 发票中心-开票记录-发票详情
  {
    path: '/invoiceDetails',
    name: 'invoiceDetails',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoiceDetails/index.vue'),
    meta: {
      name: '发票中心-发票详情',
      type: 'list'
    }
  },
  // 发票中心-开票记录-发票详情-发票文件查看
  {
    path: '/invoiceFile',
    name: 'invoiceFile',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoiceFile/index.vue'),
    meta: {
      name: '发票中心-发票详情-发票文件查看',
      type: 'list'
    }
  },
  // 发票中心-开票记录-发票详情-发票支付详情列表
  {
    path: '/invoicePayList',
    name: 'invoicePayList',
    component: () => import('@/views/invoiceCenter/invoiceHistory/invoicePayList/index.vue'),
    meta: {
      name: '发票中心-发票详情-发票支付详情列表',
      type: 'list'
    }
  },

  // 个人信息
  {
    path: '/selfInfo',
    name: 'selfInfo',
    component: () => import('@/views/selfInfo/index.vue'),
    meta: { name: '个人信息', type: 'list' }
  },
  {
    path: '/selfInfoDetail',
    name: 'selfInfoDetail',
    component: () => import('@/views/selfInfo/selfInfoDetail/index.vue'),
    meta: { name: '个人信息详情', type: 'list' }
  },
  {
    path: '/authenticateSelf',
    name: 'authenticateSelf',
    component: () => import('@/views/selfInfo/selfInfoDetail/authenticateSelf/index.vue'),
    meta: { name: '实名认证', type: 'list' }
  },
  {
    path: '/selfChangePhone',
    name: 'selfChangePhone',
    component: () => import('@/views/selfInfo/selfInfoDetail/selfChangePhone/index.vue'),
    meta: { name: '手机换绑', type: 'list' }
  },
  {
    path: '/selfInfoBindPlate',
    name: 'selfInfoBindPlate',
    component: () => import('@/views/selfInfo/selfInfoBindPlate/index.vue'),
    meta: { name: '车牌绑定', type: 'list' }
  },
  {
    path: '/selfAdviseFeedBack',
    name: 'selfAdviseFeedBack',
    component: () => import('@/views/selfInfo/selfAdviseFeedBack/index.vue'),
    meta: { name: '问题反馈', type: 'list' }
  },
  {
    path: '/selfMyFeedBack',
    name: 'selfMyFeedBack',
    component: () => import('@/views/selfInfo/selfAdviseFeedBack/selfMyFeedBack/index.vue'),
    meta: { name: '问题反馈-我的反馈', type: 'list' }
  },
  {
    path: '/selfFeedBackDetails',
    name: 'selfFeedBackDetails',
    component: () => import('@/views/selfInfo/selfAdviseFeedBack/selfFeedBackDetails/index.vue'),
    meta: { name: '问题反馈-反馈详情', type: 'list' }
  },
  {
    path: '/selfParkRecord',
    name: 'selfParkRecord',
    component: () => import('@/views/selfInfo/selfParkRecord/index.vue'),
    meta: { name: '停车记录', type: 'list' }
  },
  {
    path: '/selfBillRuleDetail',
    name: 'selfBillRuleDetail',
    component: () => import('@/views/selfInfo/selfParkRecord/selfBillRuleDetail/index.vue'),
    meta: { name: '计费规则详情页', type: 'list' }
  },
  {
    path: '/selfPayRecord',
    name: 'selfPayRecord',
    component: () => import('@/views/selfInfo/selfPayRecord/index.vue'),
    meta: { name: '缴费记录', type: 'list' }
  },

  {
    path: '/selfPayRecordInfo',
    name: 'selfPayRecordInfo',
    component: () => import('@/views/selfInfo/selfPayRecord/selfPayRecordInfo/index.vue'),
    meta: { name: '缴费记录详情', type: 'list' }
  },
  {
    path: '/selfOrderComplain',
    name: 'selfOrderComplain',
    component: () => import('@/views/selfInfo/selfPayRecord/selfPayRecordInfo/selfOrderComplain/index.vue'),
    meta: { name: '订单申诉', type: 'list' }
  },

  // 绑定车辆列表
  {
    path: '/bindPlateNumber',
    name: 'bindPlateNumber',
    component: () => import('@/views/bindPlateNumber/index.vue'),
    meta: {
      name: '绑定车辆列表',
      type: 'list'
    }
  },
  // 绑定车辆列表-绑定车牌
  {
    path: '/plateBind',
    name: 'plateBind',
    component: () => import('@/views/bindPlateNumber/plateBind/index.vue'),
    meta: {
      name: '绑定车辆列表-绑定车牌',
      type: 'list'
    }
  },

  //  公众号 意见反馈
  {
    path: '/adviseFeedBack',
    name: 'adviseFeedBack',
    component: () => import('@/views/adviseFeedBack/index.vue'),
    meta: { name: '问题反馈', type: 'list' }
  },
  // 问题反馈-我的反馈
  {
    path: '/myFeedBack',
    name: 'myFeedBack',
    component: () => import('@/views/adviseFeedBack/myFeedBack/index.vue'),
    meta: { name: '问题反馈-我的反馈', type: 'list' }
  },
  // 问题反馈-反馈详情
  {
    path: '/feedBackDetails',
    name: 'feedBackDetails',
    component: () => import('@/views/adviseFeedBack/feedBackDetails/index.vue'),
    meta: { name: '问题反馈-反馈详情', type: 'list' }
  },
  // 重庆现场-空白页
  {
    path: '/patrol',
    name: 'patrol',
    component: () => import('@/views/patrol/index.vue'),
    meta: {
      name: '重庆现场-空白页',
      type: 'list'
    }
  },
  // 重庆现场-停车代缴
  {
    path: '/parkPayForOthers',
    name: 'parkPayForOthers',
    component: () => import('@/views/patrol/parkPayForOthers/index.vue'),
    meta: {
      name: '重庆现场-停车代缴',
      type: 'list'
    }
  },
  // 停车缴费-停车代缴-在线订单
  {
    path: '/payForBehalfOnline',
    name: 'payForBehalfOnline',
    component: () => import('@/views/patrol/parkPayForOthers/payForBehalfOnline/index.vue'),
    meta: {
      name: ' 停车缴费-停车代缴-在线订单',
      type: 'list'
    }
  },
  // 扫码支付
  {
    path: '/payScanCode',
    name: 'payScanCode',
    component: () => import('@/views/payScanCode/index.vue'),
    meta: {
      name: '扫码支付',
      type: 'list'
    }
  },
  // 巡检员扫码进订单详情
  {
    path: '/partrolPersonPage',
    name: 'partrolPersonPage',
    component: () => import('@/views/partrolPersonPage/index.vue'),
    meta: { name: '巡检员扫码进订单详情', type: 'list' }
  },
  // 巡检员无牌车查询
  {
    path: '/noPlateNumberPay',
    name: 'noPlateNumberPay',
    component: () => import('@/views/noPlateNumberPay/index.vue'),
    meta: { name: '巡检员无牌车查询', type: 'list' }
  },
  // 无牌车支付
  {
    path: '/choosePayNoPlateNumber',
    name: 'choosePayNoPlateNumber',
    component: () => import('@/views/noPlateNumberPay/choosePayNoPlateNumber/index.vue'),
    meta: {
      name: '无牌车支付',
      type: 'list'
    }
  },
  {
    path: '/noPlateNumberPayEntrance',
    name: 'noPlateNumberPayEntrance',
    component: () => import('@/views/noPlateNumberPay/noPlateNumberPayEntrance/index.vue'),
    meta: {
      name: '无牌车进场',
      type: 'list'
    }
  },
  // 支付宝扫码巡检员二维码
  {
    path: '/alipayCode',
    name: 'alipayCode',
    component: () => import('@/views/alipayCode/index.vue'),
    meta: { name: '支付宝扫码巡检员二维码', type: 'list' }
  }
]

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL
})

export default router
