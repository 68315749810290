/*
 * @Description:用户端-首页-停车缴费-停车代缴-订单详情    payForParkCount
 * @Author: LinFeng
 * @Github: https://github.com/LinFeng-tt
 * @Date: 2021-04-23 17:26:05
 * @LastEditors: GeChang
 */

import request from '@/utils/request'

const prefix = '/order'

// 获取在线订单
export function queryOnlineOrderByPlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryOnlineOrderByPlateNumber',
    method: 'post',
    data: param
  })
}
// 获取订单详情
export function queryOnlineOrderBySinglePlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryOnlineOrderBySinglePlateNumber',
    method: 'post',
    data: param
  })
}
// 取历史订单
export function queryCompletedOrderByPlateNumber (param) {
  return request({
    url: prefix + '/OrderController/queryCompletedOrderByPlateNumber',
    method: 'post',
    data: param
  })
}
