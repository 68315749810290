/*
 * @Description:
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-06 15:19:32
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

export function queryOrderByDeviceId (param) {
  return request({
    url: prefix + '/OrderController/queryOrderByDeviceId' + '/' + param.deviceId,
    method: 'get'
  })
}
