/*
 * @Description:代缴详情 payOnBehalfDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-09-08 19:57:25
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'

// 获取欠费订单及在停订单
export function queryInAndLackOrder (param) {
  return request({
    url: prefix + '/OrderController/getArrearsAndUnLeaveOrderByPlateNumberAndCode',
    method: 'post',
    data: param
  })
}
