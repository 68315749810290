/*
 * @Description:下拉菜单
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-26 09:40:12
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const prefix1 = '/order'

export function queryDict (param) {
  return request({
    url: prefix + '/dict/queryDict',
    method: 'post',
    data: param
  })
}

// 公众号支付 无token
export function jsApiPayNoUserTokenBatch (param) {
  return request({
    url: prefix1 + '/wxPublicPay/jsApiPayNoUserTokenBatch',
    method: 'post',
    data: param
  })
}

// 公众号支付 有token
export function jsApiPayBatch (param) {
  return request({
    url: prefix1 + '/wxPublicPay/jsApiPayBatch',
    method: 'post',
    data: param
  })
}

// 根据车牌查询订单状态
export function queryOnlineOrderBySinglePlateNumber (param) {
  return request({
    url: prefix1 + '/OrderController/queryOnlineOrderBySinglePlateNumber',
    method: 'post',
    data: param
  })
}

// 根据停车场编号查询停车场信息
export function queryParkInfo (param) {
  return request({
    url: prefix + '/ParkController/getParkInfoById',
    method: 'post',
    data: param
  })
}
