/*
 * @Description:方向寻车 reverseSearch
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-18 12:00:37
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 获取当前选中停车场的经纬度
export function queryParkAll (param) {
  return request({
    url: prefix + '/ParkController/queryParkAll',
    method: 'post',
    data: param
  })
}
