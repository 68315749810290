/*
 * @Description: 请求封装
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:55:29
 * @LastEditors: houjinduo
 */
import axios from 'axios'
import { Toast } from 'vant'
// import router from '../router'
// import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: '/cqparkApi', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

service.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('userInfo') !== null || sessionStorage.getItem('userInfo') !== undefined) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (userInfo && userInfo.userToken) {
        config.headers.userToken = userInfo.userToken
      }
    }
    // config.headers.userToken = '3750847fbb8645afc800c0507839b944' // 线上
    // config.headers.userToken = 'c5bae3041c07c8abd8346d1b1df03113' // 公司
    // config.headers.userToken = 'fb38cde55b9e1b9d799ecb075042b54c' // 测试
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.resultCode !== '2000') {
      if (res.resultCode === '0') {
        window.android.reLogin()
      }
      Toast(res.resultMsg)
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // 错误信息提示
    return Promise.reject(error)
  }
)

export default service
