<!--
 * @Description:
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-11-22 13:51:29
 * @LastEditors: houjinduo
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    // 这里存放数据
    return {
      // 当前版本
      version: '2.0.17'
    }
  },
  watch: {
    // 监听路由变化
    $route (val) {
      this.queryVersion()
    }
  },
  methods: {
    // 查询当前的版本
    queryVersion () {
      if (window.location.href.includes('cqpark-test')) {
        // 内网
        axios.get('/official/version').then(res => {
          if (res.data.trim() !== this.version) {
            this.$router.go(0)
          }
        })
      } else {
        // 公网
        axios.get('/version').then(res => {
          if (res.data.trim() !== this.version) {
            this.$router.go(0)
          }
        })
      }
    }
  }
}
</script>

<style>
html {
  width: 100%;
  height: 100%;
  background-color: #f5f6f7;
  overflow: auto;
}
body {
  width: 100%;
  height: 100%;
  margin: 0px;
}
#app {
  width: 100%;
  height: 100%;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>
