/*
 * @Description:通用接口 commonPage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-09-08 19:57:25
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const prefix1 = '/bill'

// 计费接口
export function chargeBill (param) {
  return request({
    url: prefix1 + '/ChargingController/charge',
    method: 'post',
    data: param
  })
}

// 根据停车场编号查询停车场信息
export function queryParkInfo (param) {
  return request({
    url: prefix + '/ParkController/getParkInfoById',
    method: 'post',
    data: param
  })
}
